import React from 'react';
import {useLocation} from 'react-router-dom';
import Navbar from '../components/NavBar';
import Footer from '../components/Footer';

function PrivacyPolicy() {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const showNavigationBarsVal = queryParams.get('showNavigationBars');
    const showNavigationBars = (showNavigationBarsVal || 'true') === 'true';

    const currentPath = location.pathname;
    const isPrivacyAndTermsPath = currentPath === "/terms-and-privacy";
    console.log(`isPrivacyAndTermsPath: ${isPrivacyAndTermsPath}`);

    return (
        <div className="Privacy">
            {
                showNavigationBars ? <Navbar /> : null
            }
            <div
                style={{
                    margin: showNavigationBars ? 80 : 20,
                }}
            >
                <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
                <p></p>
                <p>Last updated January 06, 2025</p>
                <br />
                <p>
                    This Privacy Notice for Sequence Studios Limited ("<strong>we</strong>,"
                    "<strong>us</strong>," or "<strong>our</strong>") describes how and why we
                    might access, collect, store, use, and/or share ("<strong>process</strong>")
                    your personal information when you use our services ("<strong>Services</strong>"),
                    including when you:
                </p>
                <br />
                <ul class="list-disc ml-5">
                    <li>
                        Visit our website at <a href="https://www.sequence-studios.com" className="text-blue-500">https://www.sequence-studios.com</a>,
                        or any website of ours that links to this Privacy Notice.
                    </li>
                    <li>
                        Download and use our mobile application (Sequence), or any other application
                        of ours that links to this Privacy Notice.
                    </li>
                    <li>
                        Engage with us in other related ways, including any sales, marketing, or events.
                    </li>
                </ul>
                <br />
                <p>
                    <strong>Questions or concerns?</strong> Reading this Privacy Notice will help you
                    understand your privacy rights and choices. We are responsible for making decisions
                    about how your personal information is processed. If you do not agree with our
                    policies and practices, please do not use our Services. If you still have any
                    questions or concerns, please contact us at <a href="mailto:info@sequence-studios.com" className="text-blue-500">info@sequence-studios.com</a>.
                </p>

                {/* 
                    SUMMARY OF KEY POINTS
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="SUMMARY OF KEY POINTS">SUMMARY OF KEY POINTS</h2>

                <p><strong><i>This sumary provides key points from our Privacy Notice, but you can find out more details about any of these topics below.</i></strong></p>
                <br />

                <p>
                    <strong>What personal information do we process?</strong> When you visit, use, or navigate our Services, we may process personal information
                    depending on how you interact with us, the choices you make, and the products and features you use. Learn more
                    about <a href="#Personal information you disclose to us" className="text-blue-500">personal information you disclose to us</a>.
                </p>
                <br />
                <p>
                    <strong>Do we process any sensitive personal information?</strong> Some of the information may be considered "special" or "sensitive" in
                    certain jurisdictions, for example, your racial or ethnic origins, sexual orientation, and religious beliefs. We may process sensitive personal
                    information when necessary with your consent or as otherwise permitted by applicable law. Learn more
                    about <a href="#Sensitive Information" className="text-blue-500">sensitive information</a> we process.
                </p>
                <br />

                <p>
                    <strong>Do we collect any information from third parties?</strong> We may collect information from public databases, marketing partners,
                    social media platforms, and other outside sources. Learn more
                    about <a href="#Information collected from other sources" className="text-blue-500">information collected from other sources</a>.
                </p>
                <br />

                <p>
                    <strong>How do we process your information?</strong> We process your information to provide, improve, and administer our Services, communicate
                    with you, for security and fraud prevention, and to comply with the law. We may also process your information for other purposes with your consent.
                    We process your information only when we have a valid legal reason to do so. Learn more
                    about <a href="#HOW DO WE PROCESS YOUR INFORMATION?" className="text-blue-500">how we process your information</a>.
                </p>
                <br />

                <p>
                    <strong>In what situations and with which parties do we share personal information?</strong> We may share information in specific situations and
                    with specific third parties. Learn more
                    about <a href="#WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" className="text-blue-500">when and with whom we share your personal information</a>.
                </p>
                <br />

                <p>
                    <strong>How do we keep your information safe?</strong> We have adequate organizational and technical processes and procedures in place to protect
                    your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100%
                    secure. We cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security
                    and improperly collect, access, steal, or modify your information. Learn more
                    about <a href="#HOW DO WE KEEP YOUR INFORMATION SAFE?" className="text-blue-500">how we keep your information safe</a>.
                </p>
                <br />

                <p>
                    <strong>What are your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean you have certain rights
                    regarding your personal information. Learn more about <a href="#WHAT ARE YOUR PRIVACY RIGHTS?" className="text-blue-500">your privacy rights</a>.
                </p>
                <br />

                <p>
                    <strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by
                    visiting <a href="mailto:info@sequence-studios.com" className="text-blue-500">info@sequence-studios.com</a> or contacting us. We will consider and act
                    upon any request in accordance with applicable data protection laws.
                </p>
                <br />

                <p>
                    Want to learn more about what we do with any information we collect? <a href="#WHAT INFORMATION DO WE COLLECT?" className="text-blue-500">Review the Privacy Notice in full</a>.
                </p>
                <br />

                {/* 
                    WHAT INFORMATION DO WE COLLECT?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="WHAT INFORMATION DO WE COLLECT?">WHAT INFORMATION DO WE COLLECT?</h2>

                {/* Personal information you disclose to us */}
                <h3 className="text-xl font-semibold mt-5 mb-2" id="Personal information you disclose to us">Personal information you disclose to us</h3>
                <br />
                <p><i><strong>In Short:</strong> We collect personal information that you provide to us.</i></p>
                <br />
                <p>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining
                    information about us or our products and Services, participate in activities on the Services, or otherwise contact us.</p>
                <br />

                <p><strong>Personal Information Provided by You.</strong> The personal information we collect depends on your interactions with us and the
                    Services, the choices you make, and the products and features you use. The personal information we collect may include the following:</p>
                <br />
                <ul class="list-disc ml-5">
                    <li>Names</li>
                    <li>Email addresses</li>
                    <li>Usernames</li>
                    <li>Debit/Credit card numbers</li>
                    <li>Work addresses</li>
                </ul>
                <br />

                <p id="Sensitive Information"><strong>Sensitive Information.</strong> When necessary, with your consent or as permitted by applicable law,
                    we process the following categories of sensitive information:</p>
                <br />
                <ul class="list-disc ml-5">
                    <li>Financial data</li>
                </ul>
                <br />

                <p><strong>Payment Data.</strong> We may collect data necessary to process your payment if you choose to make purchases, such as your payment
                    instrument number, and the security code associated with your payment instrument. All payment data is handled and stored by RevenueCat. You may
                    find their privacy notice link here: <a href="https://www.revenuecat.com/privacy/" className="text-blue-500">RevenueCat Privacy Notice</a>.</p>
                <br />

                <p><strong>Social Media Login Data.</strong> We may provide you with the option to register with us using your existing social media account
                    details, like your Facebook or X account. If you choose to register in this way, we will collect certain profile information about you from the
                    social media provider, as described in the section
                    "<a className="text-blue-500" href='#HOW DO WE HANDLE YOUR SOCIAL LOGINS?'>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a>" below.</p>
                <br />

                <p><strong>Application Data.</strong> If you use our application(s), we may collect the following information if you choose to provide us with
                    access or permission:</p>
                <br />
                <ul class="list-disc ml-5">
                    <li><i>Geolocation Information.</i> We may request access or permission to track
                        location-based information from your mobile device, either
                        continuously or while you are using our mobile application(s), to
                        provide certain location-based services. If you wish to change our
                        access or permissions, you may do so in your device&#39;s
                        settings.</li>
                    <li><i>Mobile Device Access.</i> We may request access or permission to certain
                        features from your mobile device, including your mobile device&#39;s
                        camera, photos library, and other features. If you wish to change our
                        access or permissions, you may do so in your device&#39;s
                        settings.</li>
                    <li><i>Mobile Device Data.</i> We automatically collect device information (such
                        as your mobile device ID, model, and manufacturer), operating system,
                        version information and system configuration information, device and
                        application identification numbers, browser type and version, hardware
                        model Internet service provider and/or mobile carrier, and Internet
                        Protocol (IP) address (or proxy server). If you are using our
                        application(s), we may also collect information about the phone
                        network associated with your mobile device, your mobile device&#39;s
                        operating system or platform, the type of mobile device you use, your
                        mobile device&#39;s unique device ID, and information about the
                        features of our application(s) you accessed.</li>
                    <li><i>Push Notifications.</i> We may request to send you push notifications
                        regarding your account or certain features of the application(s). If
                        you wish to opt out from receiving these types of communications, you
                        may turn them off in your device&#39;s settings.</li>
                </ul>
                <br />
                <p>This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and
                    reporting purposes.</p>
                <br />
                <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>

                {/* Information automatically collected */}
                <h3 className="text-xl font-semibold mt-5 mb-2">Information automatically collected</h3>

                <p><i><strong>In Short:</strong> Some information—such as your Internet Protocol (IP) address and/or browser and device characteristics— is collected automatically
                    when you visit our Services.</i></p>
                <br />
                <p>We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your
                    name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system,
                    language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This
                    information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.</p>
                <br />
                <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                <br />
                <p>The information we collect includes:</p>
                <br />
                <ul class="list-disc ml-5">
                    <li><i>Log and Usage Data.</i> Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you
                        access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information,
                        browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed,
                        searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash
                        dumps"), and hardware settings).</li>
                    <li><i>Device Data.</i> We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending
                        on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location,
                        browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.</li>
                </ul>

                {/* Google API */}
                <h3 className="text-xl font-semibold mt-5 mb-2">Google API</h3>
                <p>Our use of information received from Google APIs will adhere
                    to <a href="https://developers.google.com/terms/api-services-user-data-policy" className="text-blue-500">Google API Services User Data Policy</a>, including
                    the <a href="https://developers.google.com/terms/api-services-user-data-policy#limited-use" class="text-blue-500">Limited Use requirements</a>.</p>

                {/* Information collected from other sources */}
                <h3 className="text-xl font-semibold mt-5 mb-2" id="Information collected from other sources">Information collected from other sources</h3>

                <p><i><strong>In Short:</strong> We may collect limited data from public databases, marketing partners, social media platforms, and other outside sources.</i></p>
                <br />
                <p>In order to enhance our ability to provide relevant marketing, offers, and services to you and update our records, we may obtain information about you from other
                    sources, such as public databases, joint marketing partners, affiliate programs, data providers, social media platforms, and from other third parties. This
                    information includes mailing addresses, job titles, email addresses, phone numbers, intent data (or user behavior data), Internet Protocol (IP) addresses, social
                    media profiles, social media URLs, and custom profiles, for purposes of targeted advertising and event promotion.</p>
                <br />
                <p>If you interact with us on a social media platform using your social media account (e.g., Facebook or X), we receive personal information about you from such
                    platforms such as your name, email address, and gender. You may have the right to withdraw your consent to processing your personal information. Learn more
                    about <a className="text-blue-500" href='#withdrawing your consent'>withdrawing your consent</a> . Any personal information that we collect from your social media account depends on your social media account's privacy settings. Please note that their own use of your information is not governed by this Privacy Notice.</p>
                <br />

                {/* 
                    HOW DO WE PROCESS YOUR INFORMATION?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="HOW DO WE PROCESS YOUR INFORMATION?">HOW DO WE PROCESS YOUR INFORMATION?</h2>

                <p>
                    <i><strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you,
                        for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
                    </i>
                </p>
                <br />

                <p>
                    <strong>We process your personal information for a variety of reasons, depending on how you interact with our Services, including:</strong>
                </p>
                <br />
                <ul class="list-disc ml-5">
                    <li>
                        <strong>To facilitate account creation and authentication and otherwise manage user accounts.</strong>
                        We may process your information so you can create and log in to your account, as well as keep your account in working order.
                    </li>
                    <li>
                        <strong>To request feedback.</strong>
                        We may process your information when necessary to request feedback and to contact you about your use of our Services.
                    </li>
                    <li>
                        <strong>To send you marketing and promotional communications.</strong>
                        We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences.
                        You can opt out of our marketing emails at any time. For more information, see
                        "<a className="text-blue-500" href='#WHAT ARE YOUR PRIVACY RIGHTS?'>WHAT ARE YOUR PRIVACY RIGHTS?</a>" below.
                    </li>
                    <li>
                        <strong>To protect our Services.</strong>
                        We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
                    </li>
                    <li>
                        <strong>To identify usage trends.</strong>
                        We may process information about how you use our Services to better understand how they are being used so we can improve them.
                    </li>
                    <li>
                        <strong>To determine the effectiveness of our marketing and promotional campaigns.</strong>
                        We may process your information to better understand how to provide marketing and promotional campaigns that are most relevant to you.
                    </li>
                    <li>
                        <strong>To save or protect an individual's vital interest.</strong>
                        We may process your information when necessary to save or protect an individual's vital interest, such as to prevent harm.
                    </li>
                </ul>
                <br />

                {/* 
                    WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?">WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>

                <p>
                    <i><strong>In Short:</strong>
                        We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfill our contractual obligations, to protect your rights, or to fulfill our legitimate business interests.
                    </i>
                </p>
                <br />
                <p>
                    <u><i><strong>If you are located in the EU or UK, this section applies to you.</strong></i></u>
                </p>
                <br />
                <p>
                    The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
                </p>
                <br />
                <ul class="list-disc ml-5">
                    <li>
                        <strong>Consent:</strong>
                        We may process your information if you have given us permission (i.e., consent) to use your personal information for a specific purpose. You can withdraw
                        your consent at any time. Learn more about <a className="text-blue-500" href='#withdrawing your consent'>withdrawing your consent</a>.
                    </li>
                    <li>
                        <strong>Legitimate Interests:</strong>
                        We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
                        <ul class="list-disc ml-5">
                            <li>Send users information about special offers and discounts on our products and services</li>
                            <li>Analyze how our Services are used so we can improve them to engage and retain users</li>
                            <li>Support our marketing activities</li>
                            <li>Diagnose problems and/or prevent fraudulent activities</li>
                            <li>Understand how our users use our products and services so we can improve user experience</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Legal Obligations:</strong>
                        We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
                    </li>
                    <li>
                        <strong>Vital Interests:</strong>
                        We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
                    </li>
                </ul>
                <br />
                <p>
                    <u><i><strong>If you are located in Canada, this section applies to you.</strong></i></u>
                </p>
                <br />
                <p>
                    We may process your information if you have given us specific permission (i.e., express consent) to use your personal information for a specific purpose, or in
                    situations where your permission can be inferred (i.e., implied consent). You can <a className="text-blue-500" href='#withdrawing your consent'>withdraw your consent</a> at
                    any time.
                </p>
                <br />
                <p>
                    In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
                </p>
                <br />
                <ul class="list-disc ml-5">
                    <li>If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way</li>
                    <li>For investigations and fraud detection and prevention</li>
                    <li>For business transactions provided certain conditions are met</li>
                    <li>If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim</li>
                    <li>For identifying injured, ill, or deceased persons and communicating with next of kin</li>
                    <li>If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse</li>
                    <li>If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province</li>
                    <li>If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records</li>
                    <li>If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced</li>
                    <li>If the collection is solely for journalistic, artistic, or literary purposes</li>
                    <li>If the information is publicly available and is specified by the regulations</li>
                </ul>
                <br />

                {/* 
                    WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>

                <p><i><strong>In Short:</strong> We may share information in specific situations described in this section and/or with the following third parties.</i></p>
                <br />
                <p>We may need to share your personal information in the following situations:</p>
                <br />
                <ul className="list-disc ml-5">
                    <li><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of
                        company assets, financing, or acquisition of all or a portion of our business to another company.</li>
                    <li><strong>When we use Google Maps Platform APIs.</strong> We may share your information with certain Google Maps Platform
                        APIs (e.g., Google Maps API, Places API). Google Maps uses GPS, Wi-Fi,
                        and cell towers to estimate your location. GPS is accurate to about 20
                        meters, while Wi-Fi and cell towers help improve accuracy when GPS
                        signals are weak, like indoors. This data helps Google Maps provide
                        directions, but it is not always perfectly precise.</li>
                    <li><strong>Offer Wall.</strong> Our application(s) may display a third-party hosted offer
                        wall. Such an offer wall allows third-party advertisers to offer
                        virtual currency, gifts, or other items to users in return for the
                        acceptance and completion of an advertisement offer. Such an offer
                        wall may appear in our application(s) and be displayed to you based on
                        certain data, such as your geographic area or demographic information.
                        When you click on an offer wall, you will be brought to an external
                        website belonging to other persons and will leave our application(s).
                        A unique identifier, such as your user ID, will be shared with the
                        offer wall provider in order to prevent fraud and properly credit your
                        account with the relevant reward.</li>
                </ul>
                <br />

                {/* 
                    DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?">DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>

                <p><i><strong>In Short:</strong> We may use cookies and other tracking technologies to collect and store your information.</i></p>
                <br />

                <p>
                    <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services and your account, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.</p>
                </p>
                <br />
                <p>
                    <p>We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.</p>
                </p>
                <br />
                <p>
                    <p>To the extent these online tracking technologies are deemed to be a "sale"/"sharing" (which includes targeted advertising, as defined under the
                        applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described
                        below under section "<a className="text-blue-500" href="#DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?">DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>"</p>
                </p>
                <br />
                <p>
                    <p>Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>
                </p>
                <br />

                {/* Google Analytics */}
                <h3 className="text-xl font-semibold mt-5 mb-2" id="Google Analytics">
                    Google Analytics
                </h3>
                <p>
                    <p>We may share your information with Google Analytics to track and analyze the use of the Services. The Google Analytics Advertising Features
                        that we may use include: Google Analytics Demographics and Interests Reporting, Google Display Network Impressions Reporting and Remarketing
                        with Google Analytics. To opt out of being tracked by Google Analytics across the Services,
                        visit <a className="text-blue-500" href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout.</a> You can opt
                        out of Google Analytics Advertising Features
                        through <a className="text-blue-500" href="https://myadcenter.google.com/personalizationoff?sasb=true&ref=ad-settings">Ads Settings</a> and
                        Ad Settings for mobile apps. Other opt out means
                        include <a className="text-blue-500" href="http://optout.networkadvertising.org/">http://optout.networkadvertising.org/</a> and <a className="text-blue-500" href="http://www.networkadvertising.org/mobile-choice">http://www.networkadvertising.org/mobile-choice</a>.
                        For more information on the privacy practices of Google, please visit
                        the <a className="text-blue-500" href="https://policies.google.com/privacy">Google Privacy &amp; Terms page</a>.</p>
                </p>
                <br />

                {/* 
                    HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="HOW DO WE HANDLE YOUR SOCIAL LOGINS?">HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>

                <p><i><strong>In Short:</strong> If you choose to register or log in to our Services using a social media account, we may have access to certain
                    information about you.</i></p>
                <br />
                <p>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or X logins).
                    Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive
                    may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well
                    as other information you choose to make public on such a social media platform.</p>
                <br />
                <p>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the
                    relevant Services. Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social
                    media provider. We recommend that you review their privacy notice to understand how they collect, use, and share your personal information, and how you
                    can set your privacy preferences on their sites and apps.</p>


                {/* 
                    HOW LONG DO WE KEEP YOUR INFORMATION?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="HOW LONG DO WE KEEP YOUR INFORMATION?">HOW LONG DO WE KEEP YOUR INFORMATION?</h2>

                <p><i><strong>In Short:</strong> We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice
                    unless otherwise required by law.</i></p>
                <br />

                <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention
                    period is required or permitted by law (such as tax, accounting, or other legal requirements). No purpose in this notice will require us keeping your
                    personal information for longer than the period of time in which users have an account with us.</p>
                <br />
                <p>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this
                    is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information
                    and isolate it from any further processing until deletion is possible.</p>

                {/* 
                    HOW DO WE KEEP YOUR INFORMATION SAFE?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="HOW DO WE KEEP YOUR INFORMATION SAFE?">HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>

                <p><i><strong>In Short:</strong> We aim to protect your personal information through a system of organisational and technical security measures.</i></p>
                <br />
                <p>We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information
                    we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage
                    technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will
                    not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal
                    information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure
                    environment.</p>


                {/* 
                    DO WE COLLECT INFORMATION FROM MINORS?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="DO WE COLLECT INFORMATION FROM MINORS?">DO WE COLLECT INFORMATION FROM MINORS?</h2>

                <p><i><strong>In Short:</strong> We do not knowingly collect data from or market to children under 18 years of age.</i></p>
                <br />
                <p>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that
                    you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users
                    less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records.
                    If you become aware of any data we may have collected from children under age 18, please contact us
                    at <a href="mailto:info@sequence-studios.com" className="text-blue-500">info@sequence-studios.com</a>.</p>

                {/* 
                    WHAT ARE YOUR PRIVACY RIGHTS?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="WHAT ARE YOUR PRIVACY RIGHTS?">WHAT ARE YOUR PRIVACY RIGHTS?</h2>

                <p><i><strong>In Short:</strong> Depending on your state of residence in the US or in some
                    regions, such as the European Economic Area (EEA), United Kingdom (UK),
                    Switzerland, and Canada, you have rights that allow you greater access
                    to and control over your personal information. You may review, change,
                    or terminate your account at any time, depending on your country,
                    province, or state of residence.</i></p>
                <br />

                <p>In some regions (like the EEA, UK, Switzerland, and Canada), you have
                    certain rights under applicable data protection laws. These may include
                    the right (i) to request access and obtain a copy of your personal
                    information, (ii) to request rectification or erasure; (iii) to restrict
                    the processing of your personal information; (iv) if applicable, to data
                    portability; and (v) not to be subject to automated decision-making. In
                    certain circumstances, you may also have the right to object to the
                    processing of your personal information. You can make such a request by
                    contacting us by using the contact details provided in the
                    section '<a className="text-blue-500" href="#HOW CAN YOU CONTACT US ABOUT THIS NOTICE?">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>' below.</p>
                <br />
                <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
                <br />
                <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to
                    your <a className="text-blue-500" href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">Member State data protection
                        authority</a> or <a className="text-blue-500" href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/">UK data protection
                            authority</a>.</p>
                <br />
                <p>If you are located in Switzerland, you may contact the <a className="text-blue-500" href="https://www.edoeb.admin.ch/edoeb/en/home.html">Federal Data Protection and Information
                    Commissioner</a>.</p>
                <br />
                <p><strong><u id="withdrawing your consent">Withdrawing your consent:</u></strong> If we are relying on your consent to process your personal information, which may be express and/or
                    implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by
                    contacting us by using the contact details provided in the section '<a className="text-blue-500" href="#HOW CAN YOU CONTACT US ABOUT THIS NOTICE?">HOW CAN YOU CONTACT US ABOUT
                        THIS NOTICE?</a>' below.</p>
                <br />
                <p>However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect
                    the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
                <br />
                <p><strong><u>Opting out of marketing and promotional communications:</u></strong> You can unsubscribe from our marketing and promotional communications
                    at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the
                    section <a className="text-blue-500" href="#HOW CAN YOU CONTACT US ABOUT THIS NOTICE?">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a> below. You will then be removed from the
                    marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration
                    and use of your account, to respond to service requests, or for other non-marketing purposes.</p>
                <br />

                {/* Account Information */}
                <h3 className="text-xl font-semibold mt-5 mb-2">Account Information</h3>

                <p>If you would at any time like to review or change the information in your account or terminate your account, you can:</p>
                <br />
                <ul className="list-disc ml-5">
                    <li>Log in to your account settings and update your user account.</li>
                </ul>
                <br />
                <p>Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain
                    some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with
                    applicable legal requirements.</p>
                <br />
                <p>If you have questions or comments about your privacy rights, you may email us
                    at <a href="mailto:info@sequence-studios.com" className="text-blue-500">info@sequence-studios.com</a>.</p>

                {/* 
                    CONTROLS FOR DO-NOT-TRACK FEATURES
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="CONTROLS FOR DO-NOT-TRACK FEATURES">CONTROLS FOR DO-NOT-TRACK FEATURES</h2>

                <p>Most web browsers and some mobile operating systems and mobile
                    applications include a Do-Not-Track (&quot;DNT&quot;) feature or setting
                    you can activate to signal your privacy preference not to have data
                    about your online browsing activities monitored and collected. At this
                    stage, no uniform technology standard for recognizing and implementing
                    DNT signals has been finalized. As such, we do not currently respond to
                    DNT browser signals or any other mechanism that automatically
                    communicates your choice not to be tracked online. If a standard for
                    online tracking is adopted that we must follow in the future, we will
                    inform you about that practice in a revised version of this Privacy
                    Notice.</p>
                <br />
                <p>
                    California law requires us to let you know how we respond to web
                    browser DNT signals. Because there currently is not an industry or legal
                    standard for recognizing or honoring DNT signals, we do not respond to
                    them at this time.
                </p>

                {/* 
                    DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?">DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

                <p><i><strong>In Short:</strong> If you are a resident of California, Colorado, Connecticut,
                    Delaware, Florida, Indiana, Iowa, Kentucky, Minnesota, Montana,
                    Nebraska, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or
                    Virginia, you may have the right to request access to and receive
                    details about the personal information we maintain about you and how we
                    have processed it, correct inaccuracies, get a copy of, or delete your
                    personal information. You may also have the right to withdraw your
                    consent to our processing of your personal information. These rights may
                    be limited in some circumstances by applicable law. More information is
                    provided below.</i></p>
                <br />

                {/* Categories of Personal Information We Collect */}
                <h3 className="text-xl font-semibold mt-5 mb-2">Categories of Personal Information We Collect</h3>
                <br />
                <table id="personal-information-categories">
                    <tr>
                        <th>Category</th>
                        <th>Examples</th>
                        <th>Collected</th>
                    </tr>
                    <tr>
                        <td>A. Identifiers</td>
                        <td>Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier,
                            Internet Protocol address, email address, and account name</td>
                        <td>YES</td>
                    </tr>
                    <tr>
                        <td>B. Personal information as defined in the California Customer Records statute</td>
                        <td>Name, contact information, education, employment, employment history, and financial information</td>
                        <td>YES</td>
                    </tr>
                    <tr>
                        <td>C. Protected classification characteristics under state or federal law</td>
                        <td>Gender and date of birth</td>
                        <td>YES</td>
                    </tr>
                    <tr>
                        <td>D. Commercial information</td>
                        <td>Transaction information, purchase history, financial details, and payment information</td>
                        <td>YES</td>
                    </tr>
                    <tr>
                        <td>E. Biometric information</td>
                        <td>Fingerprints and voiceprints</td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>F. Internet or other similar network activity</td>
                        <td>Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and
                            advertisements</td>
                        <td>YES</td>
                    </tr>
                    <tr>
                        <td>G. Geolocation data</td>
                        <td>Device location</td>
                        <td>YES</td>
                    </tr>
                    <tr>
                        <td>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
                        <td>Images and audio, video or call recordings created in connection with our business activities</td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>I. Professional or employment-related information</td>
                        <td>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications
                            if you apply for a job with us</td>
                        <td>YES</td>
                    </tr>
                    <tr>
                        <td>J. Education Information</td>
                        <td>Student records and directory information</td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>K. Inferences drawn from collected personal information</td>
                        <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s
                            preferences and characteristics</td>
                        <td>NO</td>
                    </tr>
                    <tr>
                        <td>L. Sensitive personal Information</td>
                        <td></td>
                        <td>YES</td>
                    </tr>
                </table>
                <br />
                <p>We only collect sensitive personal information, as defined by
                    applicable privacy laws or the purposes allowed by law or with your
                    consent. Sensitive personal information may be used, or disclosed to a
                    service provider or contractor, for additional, specified purposes. You
                    may have the right to limit the use or disclosure of your sensitive
                    personal information. We do not collect or process sensitive personal
                    information for the purpose of inferring characteristics about
                    you.</p>
                <br />
                <p>
                    We may also collect other personal information outside of these
                    categories through instances where you interact with us in person,
                    online, or by phone or mail in the context of:
                </p>
                <br />
                <ul class="list-disc ml-5">
                    <li>Receiving help through our customer support channels;</li>
                    <li>Participation in customer surveys or contests; and</li>
                    <li>Facilitation in the delivery of our Services and to respond to your
                        inquiries.</li>
                </ul>
                <br />
                <p>We will use and retain the collected personal information as needed to provide the Services or for:</p>
                <br />

                <ul className="list-disc ml-5">
                    <li>Category A - As long as the user has an account with us</li>
                    <li>Category B - As long as the user has an account with us</li>
                    <li>Category C - As long as the user has an account with us</li>
                    <li>Category D - As long as the user has an account with us</li>
                    <li>Category F - As long as the user has an account with us</li>
                    <li>Category G - As long as the user has an account with us</li>
                    <li>Category I - As long as the user has an account with us</li>
                    <li>Category L - As long as the user has an account with us</li>
                </ul>
                <br />

                {/* Sources of Personal Information */}
                <h3 className="text-xl font-semibold mt-5 mb-2">Sources of Personal Information</h3>
                <p>
                    Learn more about the sources of personal information we collect
                    in <a className="text-blue-500" href='#WHAT INFORMATION DO WE COLLECT?'>WHAT INFORMATION DO WE COLLECT?</a>
                </p>

                {/* 
                    HOW DO WE PROCESS YOUR INFORMATION?
                */}

                {/* How do we use and share your personal information? */}
                <h3 className="text-xl font-semibold mt-5 mb-2">How do we use and share your personal information?</h3>

                <p>Learn about how we use your personal information in the section, '<a className="text-blue-500" href="#HOW DO WE PROCESS YOUR INFORMATION?">HOW DO WE PROCESS YOUR
                    INFORMATION?</a>'</p>
                <br />
                <p><strong>Will your information be shared with anyone else?</strong></p>

                <p>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider.
                    Learn more about how we disclose personal information to in the section,
                    '<a className="text-blue-500" href="#WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?">WHEN AND
                        WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>'</p>
                <br />
                <p>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration.
                    This is not considered to be 'selling' of your personal information.</p>
                <br />
                <p>We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months.
                    We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.</p>
                <br />

                {/*Your Rights */}

                <h3 className="text-xl font-semibold mt-5 mb-2">Your Rights</h3>
                <p>
                    You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:
                </p>
                <br />
                <ul class="list-disc ml-5">
                    <li><strong>Right to know</strong> whether or not we are processing your personal data</li>
                    <li><strong>Right to access</strong> your personal data</li>
                    <li><strong>Right to correct</strong> inaccuracies in your personal data</li>
                    <li><strong>Right to request</strong> the deletion of your personal data</li>
                    <li><strong>Right to obtain a copy</strong> of the personal data you previously shared with us</li>
                    <li><strong>Right to non-discrimination</strong> for exercising your rights</li>
                    <li><strong>Right to opt out</strong> of the processing of your personal data if it is used for targeted advertising
                        (or sharing as defined under California's privacy law), the sale of personal data, or profiling in furtherance of decisions that
                        produce legal or similarly significant effects ("profiling")</li>
                </ul>
                <br />
                <p>
                    Depending upon the state where you live, you may also have the following rights:
                </p>
                <br />
                <ul class="list-disc ml-5">
                    <li>Right to access the categories of personal data being processed (as permitted by applicable law, including Minnesota's privacy law)</li>
                    <li>Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)</li>
                    <li>Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Minnesota's and Oregon's privacy law)</li>
                    <li>Right to review, understand, question, and correct how personal data has been profiled (as permitted by applicable law, including Minnesota's privacy law)</li>
                    <li>Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California's privacy law)</li>
                    <li>Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida's privacy law)</li>
                </ul>
                <br />

                {/* How to Exercise Your Rights */}
                <h3 className="text-xl font-semibold mt-5 mb-2">How to Exercise Your Rights</h3>
                <p>
                    To exercise these rights, you can contact us by emailing us at <a href="mailto:info@sequence-studios.com" className="text-blue-500">info@sequence-studios.com</a>,
                    or by referring to the contact details at the bottom of this document.
                </p>
                <br />
                <p>
                    Under certain US state data protection laws, you can designate an authorized agent to make a request on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with applicable laws.
                </p>
                <br />

                {/* Request Verification */}
                <h3 className="text-xl font-semibold mt-5 mb-2">Request Verification</h3>
                <p>
                    Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.
                </p>
                <br />
                <p>
                    If you submit the request through an authorized agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.
                </p>
                <br />

                {/* Appeals */}
                <h3 className="text-xl font-semibold mt-5 mb-2">Appeals</h3>
                <p>
                    Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us
                    at <a href="mailto:info@sequence-studios.com" className="text-blue-500">info@sequence-studios.com</a>. We will inform you in writing of any
                    action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is
                    denied, you may submit a complaint to your state attorney general.
                </p>

                {/* California "Shine The Light" Law */}

                <h3 className="text-xl font-semibold mt-5 mb-2">California &quot;Shine The Light&quot; Law</h3>

                <p>California Civil Code Section 1798.83, also known as the &quot;Shine
                    The Light&quot; law, permits our users who are California residents to
                    request and obtain from us, once a year and free of charge, information
                    about categories of personal information (if any) we disclosed to third
                    parties for direct marketing purposes and the names and addresses of all
                    third parties with which we shared personal information in the
                    immediately preceding calendar year. If you are a California resident
                    and would like to make such a request, please submit your request in
                    writing to us by using the contact details provided in the
                    section <a className="text-blue-500" href="#HOW CAN YOU CONTACT US ABOUT THIS NOTICE?">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
                </p>

                {/* 
                    DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?">DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?</h2>

                <p><i><strong>In Short:</strong> You may have additional rights based on the country you reside in.</i></p>

                {/* Australia and New Zealand */}
                <h3 className="text-xl font-semibold mt-5 mb-2">Australia and New Zealand</h3>

                <p>We collect and process your personal information under the obligations and conditions set by Australia's Privacy Act 1988 and New Zealand's Privacy Act
                    2020 (Privacy Act).</p>
                <br />
                <p>This privacy notice satisfies the notice requirements defined in both Privacy Acts, in particular: what personal information we collect from you, from
                    which sources, for which purposes, and other recipients of your personal information.</p>
                <br />
                <p>If you do not wish to provide the personal information necessary to fulfil their applicable purpose, it may affect our ability to provide our services,
                    in particular:</p>
                <br />
                <ul className="list-disc ml-5">
                    <li>offer you the products or services that you want</li>
                    <li>respond to or help with your requests</li>
                    <li>manage your account with us</li>
                    <li>confirm your identity and protect your account</li>
                </ul>
                <br />
                <p>At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the
                    contact details provided in the section '<a className="text-blue-500" href="#HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?">HOW CAN YOU REVIEW, UPDATE,
                        OR DELETE THE DATA WE COLLECT FROM YOU?</a>'</p>
                <br />
                <p>If you believe we are unlawfully processing your personal information, you have the right to submit a complaint about a breach of the Australian Privacy
                    Principles to the Office of the Australian Information Commissioner and a breach of New Zealand's Privacy Principles to the Office of New Zealand Privacy
                    Commissioner.</p>


                {/* Republic of South Africa */}

                <h3 className="text-xl font-semibold mt-5 mb-2">Republic of South Africa</h3>

                <p>At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the
                    contact details provided in the section '<a className="text-blue-500" href="#HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?">HOW CAN YOU REVIEW, UPDATE,
                        OR DELETE THE DATA WE COLLECT FROM YOU?</a>'</p>
                        <br />
                <p>If you are unsatisfied with the manner in which we address any complaint with regard to our processing of personal information, you can contact the office
                    of the regulator, the details of which are:</p>
                    <br />
                <a className="text-blue-500" href="https://inforegulator.org.za/"><u>The Information Regulator (South Africa)</u></a>

                <p>General enquiries: enquiries@inforegulator.org.za</p>

                <p>Complaints (complete POPIA/PAIA form 5): PAIAComplaints@inforegulator.org.za & POPIAComplaints@inforegulator.org.za</p>

                {/* 
                    DO WE MAKE UPDATES TO THIS NOTICE?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="DO WE MAKE UPDATES TO THIS NOTICE?">DO WE MAKE UPDATES TO THIS NOTICE?</h2>

                <p><i><strong>In Short:</strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.</i></p>

                <p>We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date and the updated version will be
                    effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of
                    such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting
                    your information.</p>

                {/* 
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                */}


                <h2 className="text-2xl font-bold mt-8 mb-4" id="HOW CAN YOU CONTACT US ABOUT THIS NOTICE?">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>

                <p>If you have questions or comments about this notice, you may email us
                    at <a href="mailto:info@sequence-studios.com" className="text-blue-500">info@sequence-studios.com</a> or contact us by post at:</p>

                <p>info@sequence-studios.com</p>
                <p>Sequence Studios Ltd</p>
                <p>71 - 75 Shelton Street,</p>
                <p>Covent Garden,</p>
                <p>London WC2H 9JQ,</p>
                <p>UK</p>

                {/* 
                    HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                */}

                <h2 className="text-2xl font-bold mt-8 mb-4" id="HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>

                <p>Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that
                    information, or delete it. To request to review, or update your personal information, please contact us at info@sequence-studios.com.<br /><br />To
                    delete your information open the app, navigate to Settings and tap the 'Delete account' button.</p>

                <p className='mt-4'><i>This privacy policy was created using Termly's Privacy Policy Generator.</i></p>
            </div>
            {
                (showNavigationBars && !isPrivacyAndTermsPath) ? <Footer /> : null
            }
        </div>
    );
}

export default PrivacyPolicy;
